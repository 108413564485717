:root {
  --foo: 100%;
  --mainViewHeaderHeight: 40px;
  --contentHeaderHeight: 36px;
  --ProjectHeaderHeight: 42px;
  --ProjectFooterHeight: 36px;
  --projectMobileFooterHeight: 48px;
  --mainViewMainHeaderHeight: 50px;
  --editorTextWidth: 800px;
  --editorLeftWidth: 80px;
  --editorRightWidth: 80px;
  --editorWidth: calc(var(--editorTextWidth) + var(--editorLeftWidth) + var(--editorRightWidth));
  --editorTextWidthNarrow: 436px;
  --editorLeftWidthNarrow: 80px;
  --editorRightWidthNarrow: 80px;
  --editorWidthNarrow: calc(var(--editorTextWidthNarrow) + var(--editorLeftWidthNarrow) + var(--editorRightWidthNarrow));
  --dashViewHeaderHeight: 54px;
  --fullscreenTransition: all 0.3s;

  --sideNavWidth: 52px;
  --mainWidthWithNav: calc(100% - var(--sideNavWidth) - 1px);

  --baseFont: Lato, Inter, Raleway, Poppins, 'Sans Serif';
  --buttonFont: Soleil, Lato, 'Sans Serif';
  --landingFont: Soleil, Poppins, 'Lato', 'Source Sans Pro', 'Sans Serif';

  --boxShadowSubdued: 0 0 1px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.05), 0 10px 20px rgba(0, 0, 0, 0.1);
  --modalShadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
  --boxShadowStrong: rgba(42, 47, 69, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(42, 47, 69, 0.12) 0px 2px 5px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  --boxShadowContextMenu: 4px 4px 8px rgba(0, 0, 0, 0.2), 0px 0px 16px rgba(0, 0, 0, 0.2);

  --baseColor: rgb(254, 252, 254);
  --barColor: var(--baseColor);
  --topBarColor: var(--barColor);
  --baseBorderColor: rgb(216, 215, 216);

  --testBackgroundColor: rgb(211, 209, 205);
  --viewBackgroundColor: rgb(171, 171, 171);
  --viewBackgroundLightColor: rgb(240, 240, 240);
  --viewBackgroundDarkColor: rgb(179, 179, 179);
  --viewBorderColor: rgb(191, 191, 191);

  --editor-tooltip: rgb(0, 0, 0);
  --modal-background-color: rgb(0, 0, 0, 0.3);
  --site-button-background: rgb(0, 153, 255);

  /* only here */
  --inputFontSize: 14px;

  --space-mini: 4px;
  --space-small: 8px;
  --space-medium: 16px;
  --space-large: 32px;

  /* site */
  --site-footer-height: 216px;
  --site--border-colour: rgb(180, 180, 180);
}

#root {
  height: 100%;
}

html {
  font-size: 14px;
  height: 100%;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--baseFont);
  background-color: white;
  height: 100%;
  /* height: 100vh; */
}

.App {
  position: relative;
  height: 100%;
  /* height: 100vh; */
}

p {
  font-size: 1.2rem;
  display: block;
  margin-block-start: 0.4em;
  margin-block-end: 0.4em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

h1 {
  margin: 20px 0 10px 0;
  font-size: 36px;
  color: black;
}

h1.content {
  letter-spacing: 0.5px;
  font-size: 48px;
}

h2 {
  margin: 32px 0 12px 0;
  font-size: 24px;
  color: back;
}
h2.content {
  letter-spacing: 0.2px;
}

h3 {
  margin: 0 0 10px 0;
  font-size: 20px;
  color: black;
}

a:link {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
a:visited {
  text-decoration: underline;
}

/* Register all globals here */

.FormInput {
  padding: 2px 0 2px 5px;
}
.FormInput:focus {
  border: 2px solid red;
}

.FormInputError {
  background: purple;
}

.GlobalBackDrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 299;
  background: rgb(100, 100, 100, 0.2);
}

.GlobalBackDropTransparent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 299;
}

/* WINDOW */

.szwindow {
  height: 100%;
  display: flex;
  flex: 1 1 auto;
}

.szwindow-rim {
  height: 100%;
  width: 100%;
}

.szwindow-rim-child {
  display: flex;
  height: 100%;
}

.window-main {
  border-top: none;
  background-color: white;
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;
}

.editor-chapter {
  grid-column: 2;
  grid-row: 1;
  max-width: 1000px;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  background: white;
}

.editor-chapter-phone {
  width: 100%;
}

.editor-chapter-narrow {
  margin-left: var(--editorLeftWidth);
  width: calc(100% - var(--editorLeftWidth) - var(--editorRightWidth));
}

.editor-chapter-medium {
  margin-left: var(--editorLeftWidth);
  width: calc(100% - var(--editorLeftWidth) - var(--editorRightWidth));
}

.editor-chapter-wide {
  width: var(--editorTextWidth);
  margin-left: var(--editorLeftWidth);
}

/* Min width here is incredible imporatant to prevent main-window expansion horizontally!!! */
@media only screen and (min-width: 900px) {
  .window-main {
    flex: 0;
    width: calc(100% - 2 * var(--sideNavWidth));
    flex-basis: calc(100% - 2 * var(--sideNavWidth));
    min-width: 0;
  }
}

@media only screen and (max-width: 599px) {
  .Window {
    height: calc(100% - var(--projectMobileFooterHeight));
  }
}

.writerholder {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.szgrid {
  display: grid;
  grid-template-columns: 40px 40px auto;
}

/* TABLES */

@media (max-width: 900px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr:nth-child(odd) {
    background: #ddd;
  }

  th,
  td {
    padding: 2px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:focus {
  outline: none;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.form--error-message {
  padding-top: var(--space-mini);
  padding-left: var(--space-small);
  color: rgb(160, 160, 160);
}

.szen-m-left_xs {
  margin-left: var(--space-mini);
}

.szen-m-horizontal_xs {
  margin-left: var(--space-mini);
  margin-right: var(--space-mini);
}

.szen-m-left_s {
  margin-left: var(--space-small);
}

.szen-m-left_m {
  margin-left: var(--space-medium);
}

.szen-m-right-s {
  margin-right: var(--space-small);
}

.szen-m-right-m {
  margin-right: var(--space-medium);
}

.szen-m-around_xs {
  margin: var(--space-mini);
}

.szen-m-around_s {
  margin: var(--space-small);
}

.szen-m-around_m {
  margin: var(--space-medium);
}

.szen-m-b_xs {
  margin-bottom: var(--space-mini);
}

.szen-m-b_s {
  margin-bottom: var(--space-small);
}

.szen-m-b_m {
  margin-bottom: var(--space-medium);
}

.szen-p-around_xs {
  margin: var(--space-mini);
}

.szen-p-around_s {
  margin: var(--space-small);
}

.szen-p-around_m {
  margin: var(--space-medium);
}

.typography-action {
  cursor: pointer;
}
.typography-action:hover {
  text-decoration: underline;
}
