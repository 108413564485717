/* Base  */

.szen-button {
  font-family: var(--buttonFont);
  align-self: center;
  display: flex;
  white-space: nowrap;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 12px;
  padding: 3px 6px;
  user-select: none;
  margin: '0px 0px 0px 4px';
  transition: all 0.3s;
}

.szen-button:first-of-type {
  margin-left: 0;
}
.szen-button:hover {
  background: var(--selectedBackground);
}

/* End Base */
/* Start Site */

.szen-button-site {
  align-self: center;
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  border: none;
  background-color: white;
  color: rgb(0, 118, 197);
  border: 1px solid rgb(0, 118, 197);
  outline: none;
  cursor: pointer;
  font-size: 12px;
  padding: 3px 16px;
  margin: 0px 0px 0px 5px;
  /* width: 120px; */
  height: 32px;
  border-radius: 16px;
  text-transform: uppercase;
}

.szen-button-site:hover:enabled {
  color: white;
  background-color: rgb(0, 118, 197);
}

.szen-button-site:disabled {
  color: rgb(200, 200, 200);
  border-color: rgb(200, 200, 200);
}

/* End Site */

.szen-button-standard {
  white-space: nowrap;
  align-items: center;
  color: var(--panelTextColor);
  font-size: 12px;
  padding: 4px 8px;
  user-select: none;
  border-radius: 6px;
  background-color: rgb(244, 244, 246);
  border: 1px solid rgb(240, 240, 242);
}

.szen-button-standard.szen-button-disabled {
  color: #ccc;
}

.szen-button-standard:hover {
  background: var(--selectedBackground);
}

.szen-button-action {
  color: black;
  background-color: white;
  display: inline-block;
  margin: 3px 5px;
  white-space: nowrap;
  border: 1px solid var(--appTextColor);
  padding: 4px 16px;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 40px;
  transition: all 0.2s;
}

.szen-button-action:hover {
  background: var(--appTextColor);
  color: white;
}

/* .additionalpossible {
  font-feature-settings: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-size-adjust: none;
  font-stretch: 100%;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-width: 108px;
  overflow: hidden;
  overflow-wrap: break-word;
  vertical-align: middle;
  touch-action: manipulation;
  white-space: nowrap;
  font-variant: normal;
} */
