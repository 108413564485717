#eiffel {
  animation-duration: 3s;
  animation-name: slidein;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  fill: red;
}

@keyframes slidein {
  from {
    fill: white;
  }

  to {
    fill: blue;
  }
}
