@media only screen and (min-width: 599px) {
  .szscrollbar::-webkit-scrollbar {
    width: 0.5rem;
    width: 14px;
    height: 6px;
  }

  .szscrollbar::-webkit-scrollbar-button {
  }

  .szscrollbar::-webkit-scrollbar-thumb {
    background-color: rgb(220, 220, 220);
    /* background-color: #aaa; */
    border-radius: 10px;
    margin: 0 4px;
    padding: 0 2px;
    /* width: 0.5rem; */

    /* height: 6px; */
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    /* background-color: rgba(0, 0, 0, 0.15); */
    /* -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05); */
  }

  .szscrollbar::-webkit-scrollbar-track {
    background-color: rgb(250, 250, 250);
    width: 0.5rem;

    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  }

  .szscrollbar::-webkit-scrollbar-track-piece {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
    /* border-radius: 10px; */
    /* background-color: red; */
    padding: 0 2px;
  }

  .szscrollbar::-webkit-scrollbar-corner {
  }
}

/* @media only screen and (max-width: 599px) {
  .szscrollbar::-webkit-scrollbar {
    width: auto;
  }
  .szscrollbar::-webkit-scrollbar-thumb {
    background-color: none;
    border-radius: none;
    margin: auto;
    padding: auto;
    width: auto;
  }
  ..szscrollbar::-webkit-scrollbar-track-piece {
    padding: auto;
  }
} */
