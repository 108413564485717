/* base classes */

.szen-icon {
  cursor: pointer;
  padding: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.szen-icon svg {
  height: 14px;
  width: 14px;
}

.szen-icon__large svg {
  height: 20px;
  width: 20px;
}

.szen-icon__small svg {
  height: 10px;
  width: 10px;
}

.icon-outline {
  fill: transparent;
  stroke: var(--iconMainColor);
  border-radius: 6px;
}

.icon-outline svg {
  stroke: var(--iconMainColor);
  fill: transparent;
}

.icon-filled {
  fill: var(--iconMainColor);
  stroke: transparent;
  border-radius: 5px;
}

/* base active variants   */

.icon-outline__active {
  border-radius: 5px;
  fill: transparent;
  stroke: var(--iconMainColor);
}
.icon-outline__active svg {
  stroke: transparent;
  fill: var(--iconMainColor);
}

.icon-filled__active {
  fill: var(--iconMainColor);
  stroke: transparent;
}

/* preset bordered */

.icon__bordered {
  border-radius: 50%;
  height: 20px;
  width: 20px;
}

/* .icon__bordered:hover {
  background-color: var(--iconMainHoverColor);
} */

.icon__bordered.szen-icon__small {
  height: 16px;
  width: 16px;
}

.icon__bordered.szen-icon__large {
  height: 36px;
  width: 36px;
}

/* preset hover-circle */

.hover-circle {
  border-radius: 4px;
  transition: all 0.3s;
}
.hover-circle:hover {
  background-color: var(--iconMainHoverColor);
}

/* preset hover-border-black */

.hover-border-black:hover {
  /* stroke: var(--iconHighlightColor); */
  background-color: rgb(171, 171, 171);
}

.hover-border-black:hover svg {
  stroke: white;
  /* stroke: var(--iconHighlightColor); */
  /* fill: var(--iconMainHoverColor); */
}

.hover-border-black__active {
  background: rgb(200, 200, 200);
}

.hover-border-black__active svg {
  fill: white;
  stroke: white;
  /* fill: var(--iconMainHoverColor); */
}

/* preset hover-fill */

.hover-fill svg {
  transition: all 0.3s;
}
.hover-fill:hover svg {
  fill: var(--iconMainColor);
}
