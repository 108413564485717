.com-ind-circles {
  /* border: 1px solid red; */
  animation: rotate 2s infinite linear;
  border-top: 4px solid var(--appTextColorSecondary);
  /* box-shadow: 2px 2px 2px 0 gray; */
  border-radius: 50%;
}

.spinner-two {
  border: 4px solid #ccc;
  border-right-color: transparent;
  border-radius: 50%;
  animation: rotate 1.5s infinite linear;
  /* margin: 15px 0 0 15px; */
}

@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}
