.legalnav-item-active {
  font-weight: bold;
}

.site-paragraph {
  font-size: 16px;
  line-height: 24px;
  margin-block-end: 1em;
}

.site-image {
  max-width: 100%;
}

.site-list {
  font-size: 16px;
  line-height: 24px;
  list-style-type: disc;
}
.site-listitem {
  margin-bottom: 3px;
}

.site-beta-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background: white;
  border-radius: 10px;
  align-self: center;
  padding: 30px;
  border: 1px solid white;
  font-size: 24px;
  width: 600px;
  z-index: 1;
}

.site-beta-text > .site-paragraph {
  font-size: 16px;
  line-height: 24px;
}

.site-beta-title {
  font-size: 32px;
  margin-bottom: 20px;
  font-family: Lato;
}

.topics-nav {
  width: 100%;
  grid-column: 1/5;
  padding-top: 20px;
}

.topics-articles {
  width: 100%;
  padding-top: 20px;
}

.topic-row {
  padding-left: 20px;
  width: 100%;
  margin-bottom: 40px;
}

.topic-title {
  text-transform: uppercase;
  padding-bottom: 8px;
  border-bottom: 1px solid black;
}

.article-card {
  border: 1px solid var(--site--border-colour);
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  margin: 10px 20px;
  text-transform: uppercase;
  transition: all 0.3s;
}

.article-card:hover {
  color: white;
  background: rgb(67, 138, 255);
  transform: translateY(-3px);
}

@media only screen and (min-width: 600px) {
  .topics-articles {
    display: flex;
    flex-wrap: wrap;
  }

  .article-card {
    border: 1px solid black;
    color: black;
    padding: 10px 20px;
    height: 120px;
    width: 200px;
    margin: 10px 20px;
    text-transform: uppercase;
  }

  .site-beta-text > .site-paragraph {
    font-size: 18px;
    line-height: 32px;
  }

  .site-beta-text {
    margin-right: 50px;
    margin-left: 10px;
    width: 500px;
  }
}

@media only screen and (min-width: 1200px) {
  .site-beta-text > .site-paragraph {
    font-size: 24px;
    line-height: 32px;
  }

  .site-beta-text {
    margin-right: 50px;
    margin-left: 50px;
    width: 640px;
  }
}

.site-fillerup {
  width: 100%;
  flex: 1;
  /* border-top-right-radius: 140px; */
  background: white;
  display: flex;
  /* flex-direction: column; */

  justify-content: center;
}

.site-cta {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 200px;
  max-width: 1200px;
  width: calc(100% - 60px);
  background-image: linear-gradient(90deg, rgb(0, 85, 255), rgb(0, 153, 255));
  margin-bottom: 50px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0px, rgba(0, 0, 0, 0.03) 0px 0px 8px, rgba(0, 0, 0, 0.1) 0px 20px 30px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  overflow: hidden;
}

.site-cta-action {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(0, 153, 255);
  cursor: pointer;
  height: 100px;
  width: 100px;
  font-size: 12px;
  border-radius: 50%;
  background: rgb(0, 85, 255);
  color: white;
}

@media only screen and (min-width: 599px) {
  .site-cta {
    flex-direction: row;

    height: 340px;
    font-size: 36px;
    padding-left: 100px;
    margin-bottom: 200px;
  }

  .site-cta-action {
    font-size: 26px;
    padding: 10px;
    margin-left: 20px;
    white-space: pre-wrap;
    text-align: center;
    height: 170px;
    width: 170px;
  }
}

.site--form-text-field {
  border: 1px solid var(--site--border-colour);
  border-radius: 4px;
  width: 240px;
  padding: 8px;
  font-size: 14px;
}
